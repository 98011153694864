import React, { useState } from "react";
import axios from "axios";
import logo from "./logo.png";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: ''
  });

  const [errorMessage, setErrorMessage] = useState({});
  const base_url = process.env.REACT_APP_BASE_API_URL || 'https://apisandbox.yowasteconnect.com/v2';;
  const apiKey = process.env.REACT_APP_ADC_API_KEY;;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is Required";
    } else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)){
        newErrors.email = 'Email is invalid'
    }
    if (!formData.password) {
      newErrors.password = "Password is Required";
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    let data = JSON.stringify({
        username:formData.email,
        up_password:formData.password
      });
 
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${base_url}/users/login`,
        headers: { 
          'key': apiKey, 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        },
        data : data
      };
    await axios
      .request(config)
      .then((response) => {
        toast.success('Login Successful');
        localStorage.setItem('access_token', response.data.data.access_token);
        // navigate after 3 seconds
        const navigateAfterDelay = async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          navigate("/dashboard");
          window.location.reload(false);
        };
        navigateAfterDelay();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <div className=" mt-10 flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={logo} alt="ADC Company" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="flex text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    errorMessage.email ? "border-red-500" : ""
                  }`}
                  onChange={handleChange}
                  value={formData.email}
                />
                {errorMessage.email&& (
                    <span className="text-xs text-red-500 mt-2">{errorMessage.email}</span>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
               
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    errorMessage.password ? "border-red-500" : ""
                  }`}
                  onChange={handleChange}
                  value={formData.password}
                />
                {errorMessage.password&& (
                    <span className="text-xs text-red-500 mt-2">{errorMessage.password}</span>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={handleLogin}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
