import './App.css';
import {Routes, Route} from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/Dashboard';

function App() {
  const isLoggedIn = !!localStorage.getItem('access_token');
  return (
    <div className="App">
      <Routes>
        {isLoggedIn ? (
          <Route path='*' element={<Dashboard />} />
        ) : (
          <>
          {/* <Route path='/dashboard' element={<Navigate to='/login' />} /> */}
          <Route path='*' element={<Login/>} />
          </>
        )}
        
      </Routes>
    </div>
  );
}

export default App;
