import React, { useState } from "react";
import Nav from "../nav";
import Loader from "../Loader";

function Dashboard() {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="h-screen flex flex-col">
      <Nav />
      {loading && <Loader />}
      <iframe
        title="Looker Studio Report"
        className="flex-1"
        src="https://lookerstudio.google.com/embed/reporting/45482f2f-6b8e-4fdb-a05d-bdad43dc5bf8/page/wVssD"
        frameBorder="0"
        style={{ display: loading ? "none" : "block" }}
        allowFullScreen
        onLoad={handleLoad}
      ></iframe>
    </div>
  );
}

export default Dashboard;
